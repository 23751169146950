import './overview.css'

import {useState, useEffect, useRef} from 'react'
import axios from '../../../helpers/request'

import SMSicon from '../../../components/images/smsicon.svg'
import Emailicon from '../../../components/images/emailicon.svg'

import CountUp from 'react-countup'
import {CircularProgressbar, CircularProgressbarWithChildren, buildStyles} from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import {Grid} from '@mui/material'
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from 'chart.js'

import {Bar} from 'react-chartjs-2'
import {useAppState} from '../../../state'
import {syncTimeout} from 'highcharts'
import {DateFilterComponent} from '../DateFilter/DateFilterComponent'
import CustomPopup from '../../Popup/CustomPopup'

import Cookies from 'universal-cookie'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Swal from 'sweetalert2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

let allMonths = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]
let labels = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

export const options = {
  plugins: {
    legend: {
      position: 'bottom'
    }
  },
  responsive: true,
  scales: {
    x: {
      stacked: false
    }
  },
  redraw: true
}

const defaultDataset = {
  labels,
  datasets: [
    {
      label: 'Email',
      data: [],
      backgroundColor: '#52ace9',
      barThickness: 30,
      borderRadius: 5
    },
    {
      label: 'SMS ',
      data: [],
      backgroundColor: '#da524c',
      barThickness: 35,
      borderRadius: 5
    }
  ]
}

export default function Overview() {
  let defaultSmsEmailstatusRate = {
    sms: {
      success: 0,
      fail: 0
    },
    email: {
      success: 0,
      fail: 0
    }
  }
  const {user: loggedUser} = useAppState()
  const [data, setData] = useState({TotalEmails: 0, TotalMessages: 0})

  const [smsStatusRateData, setSmsStatusRateData] = useState(defaultSmsEmailstatusRate.sms)
  const [emailStatusRateData, setEmailStatusRateData] = useState(defaultSmsEmailstatusRate.email)
  const [barCartData, setBarCartData] = useState(defaultDataset)
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [titleText, setTitleText] = useState('All Time')
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [phone, setPhoneNo] = useState('')
  const [isAddPhone, setIsAddPhone] = useState(false)
  const [countryCode, setCountryCode] = useState('')
  const cookies = new Cookies()

  const handleDateSelect = (fromDate, toDate) => {
    setTitleText(fromDate && toDate ? `${new Date(fromDate).toDateString()} - ${new Date(toDate).toDateString()}` : 'All Time')
    getDashboardData(fromDate, toDate)
    getAllServicesSent(fromDate, toDate) // Pass the dates to getAllServicesSent
    getSmsEmailDeliveryFailureRates(fromDate, toDate) // Pass the dates to getSmsEmailDeliveryFailureRates
  }

  const getDashboardData = () => {
    let url = '/dashboard-analytics-all'
    if (loggedUser.role !== 'superadmin') {
      url = '/dashboard-analytics/' + (loggedUser.added_by ? loggedUser.added_by : loggedUser.id)
    }
    if (startDate && endDate) {
      url += `?startDate=${startDate}&endDate=${endDate}`
    }
    axios.get(url).then(async response => {
      if (response.status === 200 && response.data) {
        const {TotalEmails, TotalMessages} = response.data
        setData({TotalEmails, TotalMessages})
      } else {
        throw new Error(response?.error)
      }
    })
  }

  const getAllServicesSent = () => {
    let url = '/dashboard-services-sent-all'
    if (loggedUser.role !== 'superadmin') {
      url = `/dashboard-services-sent/${loggedUser.added_by ? loggedUser.added_by : loggedUser.id}`
      if (startDate && endDate) {
        url += `?startDate=${startDate}&endDate=${endDate}`
      }
    }
    axios.get(url).then(async response => {
      if (response.status === 200 && response.data) {
        let tempLabels = response.data.map(data => allMonths[data.sent_month - 1])
        let sentMonths = [...new Set(response.data.map(data => data.sent_month))]
        let firstIndexMonth = tempLabels[0]
        let lastIndexMonth = tempLabels.length.length > 1 ? tempLabels[tempLabels.length - 1] : 0
        labels = [...new Set(tempLabels)]

        let smsData = response.data.filter(data => data.type === 0)
        let emailData = response.data.filter(data => data.type === 1)

        let smsSentMonth = smsData.map(data => data.sent_month)
        let emailSentMonth = emailData.map(data => data.sent_month)

        let smsCount = smsData.map(data => data.count)
        let emailCount = emailData.map(data => data.count)

        sentMonths.map((month, index) => {
          if (index === 0) {
            if (sentMonths[index] === smsSentMonth[index]) {
              smsCount.unshift(0)
            }
            if (sentMonths[index] === emailSentMonth[index]) {
              emailCount.unshift(0)
            }
          } else {
            if (!smsSentMonth.includes(month)) {
              smsCount.splice(index, 0, 0)
            }
            if (!emailSentMonth.includes(month)) {
              emailCount.splice(index, 0, 0)
            }
          }
        })

        if (firstIndexMonth === lastIndexMonth) {
          sentMonths.push(sentMonths[0])
          labels.push(lastIndexMonth)
        }

        smsData = smsData.map(data => data.count)
        emailData = emailData.map(data => data.count)

        const tempchartData = {
          labels,
          datasets: [
            {
              label: 'Email',
              data: emailData,
              backgroundColor: '#52ace9',
              barThickness: 30,
              borderRadius: 5
            },
            {
              label: 'SMS ',
              data: smsData,
              backgroundColor: '#da524c',
              barThickness: 35,
              borderRadius: 5
            }
          ]
        }
        setBarCartData(tempchartData)
      } else {
        throw new Error(response?.error)
      }
    })
  }

  const getSmsEmailDeliveryFailureRates = () => {
    let smsUrl = '/dashboard-sms-delivery-failure-rate'
    let emailUrl = '/dashboard-email-delivery-failure-rate'

    if (loggedUser.role !== 'superadmin') {
      smsUrl = '/company-dashboard-sms-delivery-failure-rate/' + (loggedUser.added_by ? loggedUser.added_by : loggedUser.id)
      emailUrl = '/company-dashboard-email-delivery-failure-rate/' + (loggedUser.added_by ? loggedUser.added_by : loggedUser.id)
      if (startDate && endDate) {
        smsUrl += `?startDate=${startDate}&endDate=${endDate}`
        emailUrl += `?startDate=${startDate}&endDate=${endDate}`
      }
    }

    // Make parallel API calls
    Promise.all([axios.get(smsUrl), axios.get(emailUrl)])
      .then(([smsResponse, emailResponse]) => {
        if (smsResponse.status === 200 && smsResponse.data && emailResponse.status === 200 && emailResponse.data) {
          // SMS data processing
          let smsData = smsResponse.data
          setSmsStatusRateData({success: smsData.successPercentage, fail: smsData.failPercentage})

          // Email data processing
          let emailData = emailResponse.data
          setEmailStatusRateData({success: emailData.successPercentage, fail: emailData.failPercentage})

          console.log('SMS Fail Data', smsData.failData) // Optionally use fail data if needed
        } else {
          throw new Error(smsResponse?.error || emailResponse?.error)
        }
      })
      .catch(error => {
        console.error('Error:', error)
      })
  }
  const closeNumberPopUp = async () => {
    setIsOpenPopup(false)
    const response = await axios.put(process.env.REACT_APP_API_BASEURL + '/update-phone-status', {
      phone: 'false',
      email: loggedUser?.email,
      isAdd: false
    })

    if (response.status === 200) {
      cookies.set('cs_isPopUp', response?.data?.isOpenPopup)
    }
  }

  const handleUpdateNumber = async () => {
    const checkPhone = phone.replace(countryCode, '')
    if (phone !== '' && checkPhone !== '') {
      const response = await axios.put(process.env.REACT_APP_API_BASEURL + '/update-phone-status', {
        phone: phone,
        email: loggedUser?.email,
        isAdd: true
      })
      if (response?.status === 200) {
        cookies.remove('cs_isPopUp')
        setIsAddPhone(false)
        setIsOpenPopup(false)
      }
    } else {
      Swal.fire({
        title: 'Please enter you phone number',
        confirmButtonColor: '#3085d6'
      })
    }
  }

  useEffect(() => {
    // getLogs();
    getDashboardData()
    //getDashboardDataWhatsapp()
    getAllServicesSent()
    getSmsEmailDeliveryFailureRates()
    //createWPChannel()
    const popUp = cookies.get('cs_isPopUp')
    if (popUp === 'true') {
      setIsOpenPopup(true)
    }
  }, [])

  return (
    <div>
      <DateFilterComponent onDateSelect={handleDateSelect} setStartDate={setStartDate} setEndDate={setEndDate} />
      <Grid container justifyContent="center" spacing={2} className="dashboardboxsmallcontainer">
        <Grid item xs={12} md className="dashboardboxsmall">
          <img src={SMSicon} width="300" />
          <h2>SMS</h2>
          <p>Total SMS Sent</p>
          <h3>
            <CountUp end={data.TotalMessages} />
          </h3>
        </Grid>
        <Grid item xs={12} md className="dashboardboxsmall">
          <img src={Emailicon} width="300" />
          <h2>Email</h2>
          <p>Total Emails Sent</p>
          <h3>
            <CountUp end={data.TotalEmails} />
          </h3>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12} lg className="dashboardboxsmallmed">
          <div className="completion-container">
            <span className="response-block"></span>
            <span className="title">SMS Delivery and Failure Rate ({titleText})</span>
          </div>
          <div className="completion-response-chart">
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={6} className="chart-content">
                <p className="chart-title title">Delivered</p>
                <CircularProgressbarWithChildren
                  strokeWidth={5}
                  text={(smsStatusRateData.success || 0) + '%'}
                  styles={{
                    root: {
                      height: '250px',
                      width: 'auto'
                    },
                    path: {
                      stroke: '#27AE60'
                    },
                    trail: {
                      stroke: '#9bedbd'
                    }
                  }}
                  value={smsStatusRateData.success}
                ></CircularProgressbarWithChildren>
              </Grid>
              <Grid item xs={12} md={12} lg={6} className="chart-content">
                <p className="chart-title title">Failed</p>
                <CircularProgressbarWithChildren
                  strokeWidth={5}
                  text={(smsStatusRateData.fail || 0) + '%'}
                  styles={{
                    root: {
                      height: '250px',
                      width: 'auto'
                    },
                    path: {
                      stroke: '#ee5864'
                    },
                    trail: {
                      stroke: '#f8b9bd'
                    }
                  }}
                  value={smsStatusRateData.fail}
                ></CircularProgressbarWithChildren>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} md={12} lg className="dashboardboxsmallmed">
          <div className="completion-container">
            <span className="response-block"></span>
            <span className="title">Email Delivery and Failure Rate ({titleText})</span>
          </div>
          <div className="completion-response-chart">
            <Grid container spacing={1}>
              <Grid item xs={12} md={12} lg={6} className="chart-content">
                <p className="chart-title title">Delivered</p>
                <CircularProgressbarWithChildren
                  strokeWidth={5}
                  text={(emailStatusRateData.success || 0) + '%'}
                  styles={{
                    root: {
                      height: '250px',
                      width: 'auto'
                    },
                    path: {
                      stroke: '#27AE60'
                    },
                    trail: {
                      stroke: '#9bedbd'
                    }
                  }}
                  value={emailStatusRateData.success}
                ></CircularProgressbarWithChildren>
              </Grid>
              <Grid item xs={12} md={12} lg={6} className="chart-content">
                <p className="chart-title title">Failed</p>
                <CircularProgressbarWithChildren
                  strokeWidth={5}
                  text={(emailStatusRateData.fail || 0) + '%'}
                  styles={{
                    root: {
                      height: '250px',
                      width: 'auto'
                    },
                    path: {
                      stroke: '#ee5864'
                    },
                    trail: {
                      stroke: '#f8b9bd'
                    }
                  }}
                  value={emailStatusRateData.fail}
                ></CircularProgressbarWithChildren>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={12} lg className="dashboardboxsmallmed">
          <div className="completion-container">
            <span className="response-block"></span>
            <span className="title">Campaign Send Rates ({titleText}) </span>
          </div>
          <Bar className="bar-chart completion-response-chart" options={options} data={barCartData} redraw={false} />
        </Grid>
      </Grid>

      <CustomPopup trigger={isOpenPopup} setTrigger={() => closeNumberPopUp()}>
        <div className="phone-msg">
          <div className="msg-text">Attention Users: Enable Two-Factor Authentication (2FA) Today!</div>
          <div className="msg-sub-text">
            Protect your account with an extra layer of security! Our Two-Factor Authentication (2FA) supports mobile numbers and
            is incredibly easy to use. Update your mobile number now to keep your account safe and secure.
          </div>
          <div className="main-phone">
            <button className="btn-add-phone btn btn-primary text-white br-10" onClick={() => setIsAddPhone(!isAddPhone)}>
              Add Phone number
            </button>
          </div>
        </div>
      </CustomPopup>
      <CustomPopup trigger={isAddPhone} setTrigger={() => setIsAddPhone(!isAddPhone)}>
        <div>
          <div className="form-main">
            <form>
              <div className="phone-form">
                <label>Phone No</label>
                <PhoneInput
                  inputStyle={{
                    width: '600px',
                    height: '50px'
                  }}
                  country={'ie'}
                  value={phone}
                  onChange={(data, country) => {
                    setPhoneNo(data)
                    setCountryCode(country?.dialCode)
                  }}
                />
                <div className="phone-btn-add">
                  <button className="btn btn-primary text-white br-10" type="button" onClick={() => handleUpdateNumber()}>
                    Add
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </CustomPopup>
    </div>
  )
}
